footer {
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: #EFF5ED;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.25);
  margin-top: 150px;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

p {
  margin: 0;
  color: black;
  font-size: 14px;
}

a {
  color: #999999;
  text-decoration: none;
}