.rt-login {
  background: linear-gradient(174.76deg, rgba(53, 167, 255, 0.05) 0%, #35A7FF 100%);
}

.rt-login-box {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 20px;
  padding: 50px;
}

.rt-login-box h3 {
  color: #0059D2;
  font-weight: 600;
  font-size: 24px;
}

.rt-outline-btn {
  background: rgba(53, 167, 255, 0.05);
  border: 1px solid #FFFFFF;
  border-radius: 20px;
  padding: 15px 25px;
}

.rt-outline-btn .span {
  font-weight: 400;
  font-size: 20px;
}